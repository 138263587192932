<template>
  <a-breadcrumb separator=">">
    <a-breadcrumb-item v-for="router in routerList"
                       :key="router.path">
      <router-link :to="{path: router.path, query: router.query}">
        {{ router.name }}
      </router-link>
    </a-breadcrumb-item>
    <a-breadcrumb-item>{{ lastRouter.name }}</a-breadcrumb-item>
  </a-breadcrumb>
  <a-divider />
</template>

<script>
/*
  使用示例：
  [{
      path: '',
      query: {},
      name: '',
    },
    {
      name: '',
    }]
*/

import _ from "lodash";
import { Breadcrumb } from "ant-design-vue";

export default {
  name: "RouterHeader",

  components: {
    ABreadcrumb: Breadcrumb,
    ABreadcrumbItem: Breadcrumb.Item,
  },

  props: {
    routerHeaderInfo: Array,
  },

  setup(props) {
    const routerList = _.initial(props.routerHeaderInfo);
    const lastRouter = _.last(props.routerHeaderInfo);

    return {
      routerList,
      lastRouter,
    };
  },
};
</script>
<style lang="less" scoped>
.ant-divider {
  margin: 12px 0 16px 0;
}
</style>